[
    {
        "id": "21321313131",
        "name": "IT-Objects Building Essen",
        "address": "Ruschenstraße 18, 45133 Essen",
        "buildingType": "office"
    },
    {
        "id": "213213122231",
        "name": "IT-Objects Building Essen",
        "address": "Hesterstr. 39, 58135 Hagen",
        "buildingType": "residential"
    },
    {
        "id": "2132dd122231",
        "name": "IT-Objects Building Essen",
        "address": "Avenida da Liberdade, 38 – 2, 1269-039 Lisboa",
        "buildingType": "university"
    }
]

import { inject, Injectable } from '@angular/core';
import { AppLanguageService } from '@shared/services/app-language.service';
import { AppNavigationService } from '@shared/services/app-navigation.service';
import { AppStorageService } from '@shared/services/app-storage.service';
import { AwsAmplifyService } from '@shared/services/aws-amplify.service';
import { HotkeysService } from '@shared/services/hotkeys.service';
import { ThemeService } from '@shared/services/theme.service';
import { UserService } from '@shared/services/user.service';
import { STORAGE_SSO } from '@utils/constants';

@Injectable({
    providedIn: 'root',
})
export class AppStarterService {
    private themeService = inject(ThemeService);
    private appLanguageService = inject(AppLanguageService);
    private userService = inject(UserService);
    private hotKeys = inject(HotkeysService);
    private awsAmplifyService = inject(AwsAmplifyService);
    private appNavigationService = inject(AppNavigationService);
    private appStorageService = inject(AppStorageService);

    async initServices(): Promise<boolean> {
        this.themeService.fnInit();
        this.appLanguageService.fnInit();

        this.hotKeys
            .addShortcut({
                keyPlus: 'T',
            })
            .subscribe(() => {
                this.themeService.trigger();
            });

        const valid = await this.awsAmplifyService.validateAuthSession();

        if (!valid) return false;

        const response = await this.userService.setUserData();

        if (response.value) {
            const isFromSSO: boolean = this.appStorageService.getItem(STORAGE_SSO) ?? false;
            this.userService.store.updateItem({
                isLoggedIn: !isFromSSO,
                redirectToLanding: isFromSSO,
            });
        }

        return !response.value; // hasError
    }
}

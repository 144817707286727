import { Injector } from '@angular/core';
import { Routes } from '@angular/router';
import { TRouteAccess } from '@shared/interfaces/route.interface';
import { AppRouteService } from '@shared/services/app-route.service';

const injector = Injector.create({
    providers: [{ provide: AppRouteService, useClass: AppRouteService }],
});
const appRouteService = injector.get(AppRouteService);
const routeAccess: TRouteAccess = appRouteService.routeAccess;

export const routes: Routes = [
    { path: '', redirectTo: appRouteService.defaultRoute, pathMatch: 'full' },
    {
        path: routeAccess.auth.routeConfig.path,
        canActivate: routeAccess.auth.canActivate,
        loadChildren: () => import('./pages/auth/auth.routes').then(r => r.routes),
    },
    {
        path: routeAccess.users.routeConfig.path,
        canActivate: routeAccess.users.canActivate,
        loadChildren: () => import('./pages/users/users.routes').then(r => r.routes),
    },
    {
        path: routeAccess.elev8.routeConfig.path,
        canActivate: routeAccess.users.canActivate,
        loadChildren: () => import('@pages/elev8-manager/elev8.routes').then(r => r.routes),
    },
    {
        path: routeAccess.management.routeConfig.path,
        canActivate: routeAccess.management.canActivate,
        loadChildren: () => import('./pages/management/management.routes').then(r => r.routes),
    },
    {
        path: routeAccess.iconshowcase.routeConfig.path,
        canActivate: routeAccess.iconshowcase.canActivate,
        loadChildren: () =>
            import('./pages/icons-show-case/icons-show-case.routes').then(r => r.routes),
    },
    { path: '**', redirectTo: appRouteService.defaultRoute },
];

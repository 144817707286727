import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, computed, HostBinding, inject } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/components/icon';
import { IMenu } from '@shared/components/menu/menu.interface';
import {
    IRouteAccessConfig,
    TChildrenPageType,
    TPageType,
    TRouteChildren,
} from '@shared/interfaces/route.interface';
import { UserModel } from '@shared/models/user.model';
import { AppNavigationService } from '@shared/services/app-navigation.service';
import { AppRouteService } from '@shared/services/app-route.service';
import { AppStyles, styles } from '@shared/services/app-styles.service';
import { AppService } from '@shared/services/app.service';
import { ThemeService } from '@shared/services/theme.service';
import { UserService } from '@shared/services/user.service';
import { translations } from '@shared/utils/translations';

@Component({
    selector: 'app-menu',
    template: `
        <div class="flex flex-col px-item menu">
            @for (item of menu(); track $index) {
                <div class="flex flex-row items-center my-item" (click)="trigger(item)">
                    <a
                        class="flex flex-row justify-start items-center decoration-transparent cursor-pointer"
                        [class.active]="item.isHover || rla.isActive || isActive[item.page]"
                        [routerLink]="item.children?.length ? null : item.url"
                        (mouseenter)="item.isHover = true"
                        (mouseleave)="item.isHover = false"
                        routerLinkActive
                        #rla="routerLinkActive">
                        @if (item.icon) {
                            <icon [size]="iconSize" [name]="item.icon" class="mr-item" />
                        }
                        <div class="text-md">{{ item.title | translate }}</div>
                    </a>
                    @if (item.children?.length) {
                        <div class="flex-grow"></div>
                        <icon
                            [size]="iconSize"
                            [name]="expanded[item.page] ? 'minus' : 'plus'"
                            class="cursor-pointer" />
                    }
                </div>
                <div
                    [@collapseAnimation]="expanded[item.page] ? 'expanded' : 'collapsed'"
                    class="overflow-y-hidden">
                    @for (itemChildren of item.children; track $index) {
                        <div class="flex flex-row items-center my-item">
                            <a
                                (click)="trigger(item, itemChildren)"
                                class="flex flex-row justify-start items-center decoration-transparent ml-[50px] child"
                                [class.active-child]="itemChildren.isHover || rlaC.isActive"
                                [routerLink]="itemChildren.url"
                                (mouseenter)="itemChildren.isHover = true"
                                (mouseleave)="itemChildren.isHover = false"
                                (isActiveChange)="isActive[item.page] = $event"
                                routerLinkActive
                                #rlaC="routerLinkActive">
                                @if (itemChildren.icon) {
                                    <icon
                                        [size]="iconSize"
                                        [name]="itemChildren.icon"
                                        class="mr-item" />
                                }
                                <div class="text-md">{{ itemChildren.title | translate }}</div>
                            </a>
                        </div>
                        @if (!$last) {
                            <div class=" h-[1px]"></div>
                        }
                    }
                </div>
            }
        </div>

        <div class="flex-grow"></div>

        <div class="flex flex-col text-xs">
            <div class="flex flex-row justify-center">
                <a>{{ translations.footer.terms_conditions | translate }}</a>
                &nbsp;{{ translations.footer.and | translate }}&nbsp;
                <a class="text-primary">{{ translations.footer.data_privacy | translate }}</a>
            </div>

            <div class="flex text-soft justify-center">
                {{ translations.global.version | translate }} {{ environment.version }}
            </div>
        </div>
    `,
    styles: `
        :host {
            --offset-height: var(--header-height);
            display: flex;
            flex-direction: column;
            position: fixed;
            left: 0;
            overflow-y: auto;
            overflow-x: hidden;
            box-sizing: border-box;
            background: var(--layer);
            width: var(--menu-width);
            padding-block: var(--padding-md);
            top: var(--header-height);
            height: calc(100vh - var(--header-height));
            box-shadow: var(--box-shadow-md);
            transform: translateX(-100%);
            z-index: 99;
            border-right: 1px solid var(--border-color);

            @apply shadow-lg;

            .menu {
                a {
                    text-decoration: none;
                    position: relative;
                    color: var(--text-color);

                    &:before {
                        content: '';
                        position: absolute;
                        left: -8px;
                        height: calc(100% + 10px);
                        width: 3px;
                        background: var(--primary);
                        opacity: 0;
                        transition: all var(--transition-duration-300);
                    }

                    &:hover,
                    &.active {
                        @apply font-bold text-color;
                    }

                    &:hover,
                    &.active:before {
                        opacity: 1;
                    }

                    &.child {
                        &:hover,
                        &.active-child {
                            @apply font-bold text-primary;
                        }
                    }
                }
            }
        }
    `,
    imports: [IconComponent, TranslateModule, RouterLink, RouterLinkActive],
    animations: [
        trigger('openCloseMenu', [
            state('closed', style({ transform: 'translateX(-100%)' })),
            state('open', style({ transform: 'translateX(0)' })),
            transition('open <=> closed', animate(styles.transitionDuration200 + ' ease')),
        ]),

        trigger('collapseAnimation', [
            state('collapsed', style({ height: '0' })),
            state('expanded', style({ height: '*' })),
            transition('collapsed <=> expanded', [animate('300ms ease-in-out')]),
        ]),
    ],
})
export class MenuComponent {
    protected readonly translations = translations;
    protected readonly environment = environment;

    private appRouteService = inject(AppRouteService);
    private appStyles = inject(AppStyles);
    private appNavigationService = inject(AppNavigationService);
    private router = inject(Router);

    currentRoute = inject(AppNavigationService).currentRoute;
    theme = inject(ThemeService).theme;
    menuState = inject(AppService).menuState;
    user = inject(UserService).user;
    styles = this.appStyles.styles;

    iconSize: number = 22;
    expanded: { [key: string]: boolean } = {};
    isActive: { [key: string]: boolean } = {};

    menu = computed(() => {
        const menu: IMenu[] = [];

        for (const route in this.appRouteService.routeAccess) {
            const pageType: TPageType = route as TPageType;
            if (!this.appRouteService.routeAccess[pageType].visibleOnMenu) continue;
            const item = this.getMenuItem(pageType, this.user());
            if (item) menu.push(item);
        }

        return menu;
    });

    @HostBinding('class.menu-open')
    get menuOpen() {
        return this.menuState();
    }

    @HostBinding('@openCloseMenu')
    get menuAnimationState() {
        return this.menuState() ? 'open' : 'closed';
    }

    getMenuItem(pageType: TPageType, user: UserModel): IMenu {
        const config: IRouteAccessConfig<any> = this.appRouteService.routeAccess[pageType];

        const routeAvailable: boolean = this.appRouteService.getRouteAvailability(config, user);
        const children: TRouteChildren<any> = config.routeConfig.children ?? {};

        // main path
        if (routeAvailable) {
            return {
                page: pageType,
                title: config.title ?? '',
                url: this.appRouteService.getPath(pageType),
                userTypes: config.userTypes,
                icon: config.icon,
                children: Object.keys(children)
                    .filter(page => {
                        const configChildren = children[page];
                        return this.appRouteService.getRouteAvailability(configChildren, user);
                    })
                    .map((page: any) => {
                        const configChildren = children[page];
                        const url = this.appRouteService.getPath(pageType, page);
                        this.expanded[pageType] = this.expanded[pageType]
                            ? this.expanded[pageType]
                            : this.currentRoute() === url;
                        return {
                            page: page,
                            title: configChildren.title ?? '',
                            url: url,
                            userTypes: configChildren.userTypes,
                            icon: configChildren.icon,
                        };
                    }),
            };
        }
        return {} as IMenu;
    }

    trigger(menu: IMenu, menuChildren?: IMenu<TChildrenPageType>): void {
        if (!menuChildren && menu.children?.length)
            this.expanded[menu.page] = !this.expanded[menu.page];
        else this.navigateTo(menu.page, menuChildren?.page);
    }

    navigateTo(page: TPageType, children?: TChildrenPageType) {
        this.appNavigationService.navigateTo(page, {
            parameter: children?.toString() ?? '',
            from: 'menu',
        });
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    effect,
    inject,
    Signal,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@shared/services/app.service';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DeviceInfoService } from '@shared/services/device-info.service';
import { HeaderComponent } from '@shared/components/header/header.component';
import { FooterComponent } from '@shared/components/footer/footer.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { AppStarterService } from '@shared/services/app-starter.service';
import { UserStoreService } from '@shared/services/user-store.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { MenuComponent } from '@shared/components/menu/menu.component';
import { PageMargin, PageMarginBottomHalf } from '@shared/directives/page-margin.directive';

@Component({
    selector: 'app-root',
    standalone: true,
    template: `
        <!-- LAYOUT -->
        @if (loggedIn()) {
            <div class="flex-row" [class.main-container]="isTablet()">
                <div class="side-sticky">
                    <app-menu />
                </div>
                <!-- CONTENT PAGE -->
                <div
                    id="pageDefault"
                    class="flex-column"
                    [class.tablet]="isTablet()"
                    [class.menu-open]="menuState()"
                    page-margin-bottom-half>
                    <app-header />
                    <div class="position-relative" page-margin>
                        <router-outlet #outlet="outlet" />
                    </div>
                    <div class="flex"></div>
                    <app-footer />
                </div>
            </div>
        } @else {
            <router-outlet #outlet="outlet" />
        }
        <!-- EXTRAS -->
        <spinner />
    `,
    styles: `
        .main-container {
            height: 100vh;
        }

        .side-sticky {
            z-index: 999;
            position: sticky;
            top: 0;
            height: 100%;
        }

        #pageDefault {
            width: 100%;

            &.tablet {
                width: 100%;
            }

            &:not(.tablet) {
                width: calc(100% - var(--menu-width));
            }
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MenuComponent,
        PageMarginBottomHalf,
        HeaderComponent,
        PageMargin,
        RouterOutlet,
        FooterComponent,
        SpinnerComponent,
        PageMarginBottomHalf,
        PageMargin,
    ],
})
export class AppComponent {
    private appStarterService: AppStarterService = inject(AppStarterService);
    private translate: TranslateService = inject(TranslateService);
    private userStoreService: UserStoreService = inject(UserStoreService);
    private appService: AppService = inject(AppService);
    private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
    private dialog: MatDialog = inject(MatDialog);
    private spinner: SpinnerService = inject(SpinnerService);
    public router: Router = inject(Router);

    isTablet: Signal<boolean> = inject(DeviceInfoService).isTablet;
    loggedIn: Signal<boolean> = this.userStoreService.isLoggedIn;
    loading: Signal<boolean> = this.appService.loading;
    menuState: Signal<boolean> = this.appService.menuState;

    constructor() {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');

        // block scroll behaviour when opening dialog
        this.dialog.afterOpened.subscribe(dialog => {
            this.bodyEl?.style.setProperty('overflow', 'hidden');
            this.bodyEl?.style.setProperty('overscroll-behavior', 'contain');
            this.htmlEl?.classList.remove('cdk-global-scrollblock');
            this.htmlEl?.classList.add('cdk-global-scrollnoop');
        });
        this.dialog.afterAllClosed.subscribe(() => {
            this.bodyEl?.style.removeProperty('overflow');
            this.bodyEl?.style.removeProperty('overscroll-behavior');
            this.htmlEl?.classList.remove('cdk-global-scrollnoop');
        });

        // show/hide spinner on navigation events (start and end)
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) this.spinner.show();
            else if (event instanceof NavigationEnd) this.spinner.hide();
        });

        effect(() => {
            // remove scroll when menu opened
            if (this.isTablet()) {
                if (this.appService.menuState()) {
                    this.bodyEl?.style.setProperty('overflow', 'hidden');
                } else {
                    this.bodyEl?.style.removeProperty('overflow');
                }
            }
        });

        this.init();
    }

    async init(): Promise<void> {
        await this.appStarterService.initServices();
        this.appStarterService.initUser();
    }

    get bodyEl(): HTMLElement {
        return document.getElementById('body') as HTMLElement;
    }

    get htmlEl(): HTMLElement | null {
        return document.getElementsByTagName('html')[0];
    }
}

import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AwsAmplifyService } from '@shared/services/aws-amplify.service';
import { from, switchMap } from 'rxjs';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
    const awsAmplifyService = inject(AwsAmplifyService);
    return from(awsAmplifyService.validateAuthSession()).pipe(
        switchMap(token => {
            const authReq = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return next(authReq);
        }),
    );
};

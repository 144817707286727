import { ApplicationRef, Component, inject, signal, Signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserStoreService } from '@shared/services/user-store.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { AppService } from '@shared/services/app.service';
import { TranslateModule } from '@ngx-translate/core';
import { ThemeService } from '@shared/services/theme.service';
import { IconComponent, TIcon } from '@shared/components/icon/icon.component';
import { CustomDialogComponent } from '@shared/components/custom-dialog';
import {
    CustomDialogActionsDirective,
    CustomDialogContentDirective,
    CustomDialogHeaderRightDirective,
} from '@shared/components/custom-dialog/directives';
import { CustomCardComponent } from '@shared/components/custom-card';
import { ItemHighlightComponent } from '@shared/components/item-highlight';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomButtonComponent } from '@shared/components/custom-button';
import { AppStylesService } from '@shared/services/app-styles.service';
import { SecondaryLayerColor } from '@shared/directives/theme-color.directive';
import { IStyleVariable } from '@shared/interfaces/style-variable.interface';
import { UserDataModel } from '@shared/models/user-data.model';
import { translations } from '@shared/utils/translations';

@Component({
    standalone: true,
    selector: 'app-user-profile-dialog',
    template: `
        <custom-dialog label="" minWidth="300">
            <ng-template customDialogHeaderRight>
                <div class="flex-row align-start-center item-margin-right">
                    <icon [name]="iconTheme()" size="28" class="item-margin-right" />
                    <mat-slide-toggle
                        color="primary"
                        (click)="changeTheme()"
                        [checked]="iconTheme() === 'dark_mode'" />
                </div>
            </ng-template>

            <ng-template customDialogContent>
                @if (user(); as user) {
                    <custom-card class="flex-column align-center-center" secondary-layer-color>
                        <icon name="user" size="80" [customColor]="styles().textColorSoft" />
                        <div class="flex-column align-center-center">
                            <div class="text-color-soft">{{ user.email }}</div>

                            @if (user.role.admin) {
                                <div class="flex-row align-start-center item-margin-top">
                                    <item-highlight primary>
                                        {{ translations.global.admin | translate }}
                                    </item-highlight>
                                </div>
                            }

                            @if (user.role.user) {
                                <div class="flex-row align-start-center item-margin-top">
                                    <item-highlight primary>
                                        {{ translations.global.user | translate }}
                                    </item-highlight>
                                </div>
                            }
                        </div>
                    </custom-card>
                }
            </ng-template>

            <ng-template customDialogActions>
                <custom-button
                    (click)="changePassword()"
                    [disabled]="loading()"
                    [label]="translations.auth.change_password"
                    class="item-margin-bottom"
                    icon="key"
                    type="stroked"
                    color="primary"
                    fill />
                <custom-button
                    (click)="logout()"
                    [disabled]="loading()"
                    [loadingInside]="loading()"
                    icon="logout"
                    [label]="translations.global.logout"
                    type="flat"
                    color="delete"
                    fill />
            </ng-template>
        </custom-dialog>
    `,
    imports: [
        CommonModule,
        MatMenuModule,
        MatIconModule,
        TranslateModule,
        IconComponent,
        CustomDialogComponent,
        CustomDialogActionsDirective,
        CustomDialogContentDirective,
        CustomCardComponent,
        SecondaryLayerColor,
        ItemHighlightComponent,
        CustomDialogHeaderRightDirective,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        CustomButtonComponent,
        SecondaryLayerColor,
    ],
})
export class UserProfileDialogComponent {
    protected readonly translations = translations;

    private userStoreService: UserStoreService = inject(UserStoreService);
    private appService: AppService = inject(AppService);
    private themeService: ThemeService = inject(ThemeService);
    private applicationRef: ApplicationRef = inject(ApplicationRef);
    private appStylesService: AppStylesService = inject(AppStylesService);

    loading: WritableSignal<boolean> = signal<boolean>(false);
    user: Signal<UserDataModel> = this.userStoreService.user;
    iconTheme: Signal<TIcon> = this.themeService.iconTheme;
    styles: Signal<IStyleVariable> = this.appStylesService.styles;

    constructor(private dialogRef: MatDialogRef<UserProfileDialogComponent>) {}

    changeTheme(): void {
        this.themeService.trigger();
        this.appStylesService.fnInit(); // refresh variables
        this.applicationRef.tick(); // refresh application
    }

    changePassword(): void {
        this.dialogRef.close();
        this.appService.changePassword();
    }

    logout(): void {
        if (this.loading()) return;

        this.loading.set(true);

        setTimeout(() => {
            this.dialogRef.close();
            this.appService.logout();
        }, 800);
    }
}

import { Component, HostBinding, inject, OnDestroy, OnInit, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from './services/header.service';
import { CommonModule } from '@angular/common';
import { HeaderTitlePipe } from '@shared/components/header/pipes/header-title.pipe';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ITitleName } from '@shared/components/header/interfaces';
import { DestroyerComponent } from '@shared/components/destroyer-component/destroyer.component';
import { UserPictureProfileComponent } from '@shared/components/user-picture-profile/user-picture-profile.component';
import { UserProfileDialogComponent } from '@shared/components/user-profile-dialog';
import { DeviceInfoService } from '@shared/services/device-info.service';
import { PageMarginBottom } from '@shared/directives/page-margin.directive';
import { AppRouteService } from '@shared/services/app-route.service';
import { TRouteAccess } from '@shared/interfaces/route.interface';

@Component({
    standalone: true,
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [CommonModule, HeaderTitlePipe, MatToolbarModule, UserPictureProfileComponent],
    hostDirectives: [PageMarginBottom],
})
export class HeaderComponent extends DestroyerComponent implements OnInit, OnDestroy {
    public router: Router = inject(Router);
    private appRouteService = inject(AppRouteService);
    private header: HeaderService = inject(HeaderService);
    readonly UserProfileDialogComponent = UserProfileDialogComponent;

    titleMap: TRouteAccess = { ...this.appRouteService.routeAccess };
    title: string = '';

    isTablet: Signal<boolean> = inject(DeviceInfoService).isTablet;
    middleText$ = this.header.middleTextObservable$;

    constructor() {
        super();

        this.header.titleObservable$
            .pipe(this.takeUntilDestroyed())
            .subscribe((value: ITitleName) => {
                if (value.titleTKey) this.titleMap[value.page].title = value.titleTKey;
                if (value.subTitleTKey) this.titleMap[value.page].subtitle = value.subTitleTKey;
            });
    }

    ngOnInit(): void {
        this.header.middleText = '';
    }

    @HostBinding('class.header')
    get classes() {
        return true; // track element for some views (e.g. track height)
    }

    @HostBinding('class.tablet')
    get tabletClass() {
        return this.isTablet();
    }
}

import { Component, inject } from '@angular/core';
import { LoadingService } from '@shared/components/loading/loading.service';

@Component({
    standalone: true,
    selector: 'loading',
    template: `
        @if (state()) {
            <div class="container-loading">
                <div class="loading">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="var(--primary)"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        style="margin: auto; background: none; display: block; shape-rendering: auto;"
                        width="50px"
                        height="50px"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid">
                        <g transform="rotate(0 50 50)">
                            <rect x="46.5" y="2.5" rx="0" ry="0" width="7" height="27">
                                <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.9166666666666666s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(30 50 50)">
                            <rect x="46.5" y="2.5" rx="0" ry="0" width="7" height="27">
                                <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.8333333333333334s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(60 50 50)">
                            <rect x="46.5" y="2.5" rx="0" ry="0" width="7" height="27">
                                <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.75s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(90 50 50)">
                            <rect x="46.5" y="2.5" rx="0" ry="0" width="7" height="27">
                                <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.6666666666666666s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(120 50 50)">
                            <rect x="46.5" y="2.5" rx="0" ry="0" width="7" height="27">
                                <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.5833333333333334s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(150 50 50)">
                            <rect x="46.5" y="2.5" rx="0" ry="0" width="7" height="27">
                                <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.5s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(180 50 50)">
                            <rect x="46.5" y="2.5" rx="0" ry="0" width="7" height="27">
                                <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.4166666666666667s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(210 50 50)">
                            <rect x="46.5" y="2.5" rx="0" ry="0" width="7" height="27">
                                <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.3333333333333333s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(240 50 50)">
                            <rect x="46.5" y="2.5" rx="0" ry="0" width="7" height="27">
                                <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.25s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(270 50 50)">
                            <rect x="46.5" y="2.5" rx="0" ry="0" width="7" height="27">
                                <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.16666666666666666s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(300 50 50)">
                            <rect x="46.5" y="2.5" rx="0" ry="0" width="7" height="27">
                                <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="-0.08333333333333333s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(330 50 50)">
                            <rect x="46.5" y="2.5" rx="0" ry="0" width="7" height="27">
                                <animate
                                    attributeName="opacity"
                                    values="1;0"
                                    keyTimes="0;1"
                                    dur="1s"
                                    begin="0s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                    </svg>
                </div>
            </div>
        }
    `,
    styles: `
        :host {
            .container-loading {
                width: 100vw;
                height: 100vh;
                position: absolute;
                top: 0;
                z-index: 999999;
                left: 0;
            }
            .loading {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 9999;
                position: absolute;
            }
        }
    `,
})
export class LoadingComponent {
    state = inject(LoadingService).state;
}

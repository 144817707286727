import { inject, Pipe, PipeTransform } from '@angular/core';
import { IMenu } from '@shared/components/menu/menu.models';
import { TPageType } from '@shared/interfaces/route.interface';
import { AppRouteService } from '@shared/services/app-route.service';

@Pipe({
    standalone: true,
    name: 'menuActive',
})
export class MenuActivePipe implements PipeTransform {
    private routeService = inject(AppRouteService);

    transform(item: IMenu, url: string): boolean {
        if (url == '/') return item.url == 'users';

        const pageType: TPageType = url.split('/')[1] as TPageType;
        if (pageType) {
            const page = this.routeService.routeAccess[pageType];
            return item.url == page.routeConfig.path;
        }

        return false;
    }
}

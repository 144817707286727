import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@env/environment';
import { MockDataService } from '@shared/services/mock-data.service';
import { catchError, of, throwError, timeout } from 'rxjs';

export const mockInterceptor: HttpInterceptorFn = (req, next) => {
    const mockService = inject(MockDataService);

    if (!environment.mockService) return next(req);

    return next(req).pipe(
        timeout(5000),
        catchError(() => {
            const mockReqInfo = {
                url: req.url,
                body: req.body,
            };

            switch (req.method) {
                case 'GET':
                    return handleResponse(mockService.get(mockReqInfo));

                case 'POST':
                    return handleResponse(mockService.post(mockReqInfo));

                case 'PUT':
                    return handleResponse(mockService.put(mockReqInfo));

                case 'DELETE':
                    return handleResponse(mockService.delete(mockReqInfo));

                default:
                    return throwError(() => new Error('Method not supported'));
            }
        }),
    );
};

const handleResponse = (response: any) => {
    return of(
        new HttpResponse({
            body: response,
            status: 200,
        }),
    );
};

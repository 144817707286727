import { Component, effect, HostBinding, inject, Signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { UserStoreService } from '@shared/services/user-store.service';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { IMenu } from '@shared/components/menu/menu.models';
import { MenuActivePipe } from '@shared/components/menu/pipes/menu-active.pipe';
import { LongTextTickerDirective } from '@shared/directives/long-text-ticker.directive';
import { AppService } from '@shared/services/app.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DeviceInfoService } from '@shared/services/device-info.service';
import { AppRouteService } from '@shared/services/app-route.service';
import { IRouteAccessConfig, TPageType } from '@shared/interfaces/route.interface';

@Component({
    standalone: true,
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    imports: [
        MatListModule,
        MatIconModule,
        MatTooltipModule,
        CommonModule,
        TranslateModule,
        IconComponent,
        MenuActivePipe,
        LongTextTickerDirective,
        RouterLink,
    ],
    animations: [
        trigger('menuExpanded', [
            state('collapsed', style({ transform: 'translateX(calc(var(--menu-width) * -1))' })),
            state('expanded', style({ transform: 'translateX(0)' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class MenuComponent {
    private userData: UserStoreService = inject(UserStoreService);
    public router: Router = inject(Router);
    private appRouteService = inject(AppRouteService);
    private appService: AppService = inject(AppService);

    menu: IMenu[] = this.menuModel;
    loading$: Observable<boolean> = this.appService.loadingObservable$;

    isTablet: Signal<boolean> = inject(DeviceInfoService).isTablet;
    menuState: Signal<boolean> = this.appService.menuState;

    @HostBinding('@menuExpanded')
    get animationState() {
        return this.isTablet() ? (this.menuState() ? 'expanded' : 'collapsed') : '';
    }

    @HostBinding('class.tablet')
    get tabletClass() {
        return this.isTablet();
    }

    @HostBinding('class.opened')
    get menuOpened() {
        return this.appService.menuState();
    }

    constructor() {
        effect(() => {
            if (this.isTablet()) this.appService.closeMenu();
            else this.appService.openMenu();
        });
    }

    triggerMenu(): void {
        if (this.appService.menuState()) this.appService.closeMenu();
        else this.appService.openMenu();
    }

    get menuModel(): IMenu[] {
        const menu: IMenu[] = [];

        for (const route in this.appRouteService.routeAccess) {
            const pageType: TPageType = route as TPageType;
            const config: IRouteAccessConfig<any> = this.appRouteService.routeAccess[pageType];
            const routeAvailable: boolean = this.appRouteService.getRouteAvailability(
                config,
                this.userData.user(),
            );
            const path: string = config.routeConfig.path;

            // main path
            if (routeAvailable) {
                menu.push({
                    tKey: config.title ?? '',
                    url: config.routeConfig.path,
                    execFunction: async () => {
                        // Assuming you have access to the router instance
                        await this.router.navigate([path]);
                    },
                    icon: config.icon,
                });
            }
        }

        return menu;
    }
}

import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';
import me from '@assets/mocks/me.json';
import users from '@assets/mocks/users.json';
import properties from '@assets/mocks/properties.json';
import { delay } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MockDataService implements InMemoryDbService {
    dynamicRoutes: any = {};

    createDb() {
        this.dynamicRoutes[`/api/me`] = me;
        this.dynamicRoutes[`/api/users/`] = users;
        this.dynamicRoutes[`/api/properties/`] = properties;

        return { ...this.dynamicRoutes };
    }

    get(reqInfo: any) {
        if (['assets', 'icons'].indexOf(reqInfo.collectionName) > -1) return undefined;
        // console.log(reqInfo.url, this.dynamicRoutes[reqInfo.url]);
        if (this.dynamicRoutes[reqInfo.url]) {
            return reqInfo.utils
                .createResponse$(() => {
                    return {
                        body: this.dynamicRoutes[reqInfo.url],
                        status: 200,
                    };
                })
                .pipe(delay(250));
        }

        return undefined; // Continue processing other requests
    }

    delete(reqInfo: any) {
        // Remove the ID and get base route
        const urlParts = reqInfo.url.split('/');
        urlParts.pop(); // Remove the ID
        const baseRoute = urlParts.join('/') + '/';

        if (this.dynamicRoutes[baseRoute]) {
            const collection = this.dynamicRoutes[baseRoute];
            const id = reqInfo.url.split('/').pop(); // Get the ID

            if (Array.isArray(collection)) {
                const index = collection.findIndex((item: any) => item.id === id);
                if (index !== -1) {
                    collection.splice(index, 1);
                    return reqInfo.utils
                        .createResponse$(() => ({
                            body: { message: 'Item deleted successfully' },
                            status: 200,
                        }))
                        .pipe(delay(250));
                }
            }
        }

        return reqInfo.utils
            .createResponse$(() => ({
                body: { message: 'Item not found' },
                status: 404,
            }))
            .pipe(delay(250));
    }
}

import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[long-text-ticker]',
})
export class LongTextTickerDirective {
    @HostBinding('style.--indent.px')
    indent = 0;

    @HostBinding('style.transitionDuration.ms')
    get duration(): number {
        return -20 * this.indent;
    }

    @HostListener('mouseenter', ['$event.target'])
    onMouseEnter({ scrollWidth, clientWidth }: HTMLElement) {
        this.indent = clientWidth - scrollWidth;
    }

    @HostListener('mouseover', ['$event.target'])
    onMouseOver(el: HTMLElement) {
        el.style.setProperty('text-indent', 'var(--indent)');
        el.style.setProperty('text-overflow', 'clip');
        el.style.setProperty('user-select', 'none');
    }

    @HostListener('mouseleave', ['$event.target'])
    onMouseLeave(el: HTMLElement) {
        el.style.removeProperty('text-indent');
        el.style.removeProperty('text-overflow');
        el.style.removeProperty('user-select');
    }
}

[
    {
        "id": "usr_123abc",
        "firstName": "Emma",
        "lastName": "Johnson",
        "email": "emma.johnson@company.com",
        "country": "United States",
        "toolAccess": true,
        "enPlugAccess": false,
        "verticalCityAccess": true
    },
    {
        "id": "usr_456def",
        "firstName": "Marco",
        "lastName": "Silva",
        "email": "m.silva@company.com",
        "country": "Brazil",
        "toolAccess": true,
        "enPlugAccess": true,
        "verticalCityAccess": false
    },
    {
        "id": "usr_789ghi",
        "firstName": "Sophie",
        "lastName": "Chen",
        "email": "sophie.c@company.com",
        "country": "Singapore",
        "toolAccess": false,
        "enPlugAccess": true,
        "verticalCityAccess": true
    },
    {
        "id": "usr_101jkl",
        "firstName": "Luis",
        "lastName": "Garcia",
        "email": "l.garcia@company.com",
        "country": "Spain",
        "toolAccess": true,
        "enPlugAccess": true,
        "verticalCityAccess": true
    }
]

import { HttpBackend, HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    InMemoryScrollingOptions,
    provideRouter,
    withComponentInputBinding,
    withInMemoryScrolling,
    withViewTransitions,
} from '@angular/router';
import { headersInterceptor } from '@core/interceptor/headers-interceptor';
import { mockInterceptor } from '@core/interceptor/mock-interceptor';
import { tokenInterceptor } from '@core/interceptor/token-interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { routes } from './app.routes';

const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'disabled',
};

export const appConfig: ApplicationConfig = {
    providers: [
        DialogService,
        MessageService,
        provideRouter(
            routes,
            withComponentInputBinding(),
            withViewTransitions(),
            withInMemoryScrolling(scrollConfig),
        ),
        provideAnimations(),
        provideHttpClient(
            withInterceptors([tokenInterceptor, headersInterceptor, mockInterceptor]),
        ),
        provideAnimations(),
        importProvidersFrom(
            TranslateModule.forRoot({
                defaultLanguage: 'en',
                loader: {
                    provide: TranslateLoader,
                    useFactory: (httpHandler: HttpBackend) => {
                        return new TranslateHttpLoader(
                            new HttpClient(httpHandler),
                            './assets/i18n/',
                        );
                    },
                    deps: [HttpBackend],
                },
            }),
        ),
    ],
};

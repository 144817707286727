import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { tools } from '@shared/utils/tools';
import { Observable } from 'rxjs';

export interface IKeyEvent {
    element: any;
    description: string;
    keys: string;
    keyText: string;
    keyPlus: string;
    oneKey: boolean | false;
}

@Injectable({
    providedIn: 'root',
})
export class HotkeysService {
    private _hotkeys: WritableSignal<IKeyEvent[]> = signal([]);
    defaults: Partial<IKeyEvent>;

    hotkeys = this._hotkeys.asReadonly();

    constructor(
        private eventManager: EventManager,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.defaults = {
            element: this.document,
        };
        // document.body.addEventListener("keydown", function (event) {
        //   console.log(event)
        //   console.log(event.key)
        // });
    }

    addShortcut(options: Partial<IKeyEvent>) {
        // Add 'command' key for macOS when plus key is an arrow (ex. 'meta.ArrowRight')
        if (options.keyPlus?.includes('Arrow') && !tools.isWindows()) {
            options.keyPlus = 'meta.' + options.keyPlus;
        }

        if (!options.keys && !options.oneKey) options.keys = this.getKeys(options.keyPlus);
        if (!options.keyText)
            options.keyText = this.getKeyText(options.keyPlus, options.keys, options.oneKey);

        const merged = { ...this.defaults, ...options };
        const event = `keydown.${merged.keys}`;

        this._hotkeys.update(keys => [...keys, <IKeyEvent>merged]);

        return new Observable(observer => {
            const handler = (e: any) => {
                e.preventDefault();
                observer.next(e);
            };

            const dispose = this.eventManager.addEventListener(merged.element, event, handler);

            return () => {
                dispose();
                for (let i = 0; i < this._hotkeys().length; i++) {
                    if (this._hotkeys()[i].keys === merged.keys) this._hotkeys().splice(i, 1);
                }
            };
        });
    }

    getKeys(keyPlus: string | undefined): string {
        return tools.isWindows() ? 'Alt.' + keyPlus : 'Control.' + keyPlus;
    }

    getKeyText(keyPlus: string | undefined, keys: string | undefined, oneKey: undefined | boolean) {
        if (oneKey) return [keys + ''].join(' + ');
        else if (tools.isWindows()) {
            return ['ALT', keyPlus + ''].join(' + ');
        } else {
            if (keyPlus?.includes('meta')) {
                const last = keyPlus?.split('.')[1];
                return ['Control', 'meta', last + ''].join(' + ');
            } else return ['Control', keyPlus + ''].join(' + ');
        }
    }
}

import { Component, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CustomSpinnerComponent } from '@shared/components/custom-spinner';
import { IconComponent, TIcon } from '@shared/components/icon';
import { LoadingComponent, LoadingService } from '@shared/components/loading';
import { MenuComponent } from '@shared/components/menu';
import { AppNavigationService } from '@shared/services/app-navigation.service';
import { AppStarterService } from '@shared/services/app-starter.service';
import { AppStorageService } from '@shared/services/app-storage.service';
import { styles } from '@shared/services/app-styles.service';
import { AppService } from '@shared/services/app.service';
import { CustomMessageService } from '@shared/services/custom-message.service';
import { UserService } from '@shared/services/user.service';
import { translations } from '@shared/utils/translations';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';

@Component({
    selector: 'app-root',
    imports: [
        RouterOutlet,
        CustomSpinnerComponent,
        DropdownModule,
        FormsModule,
        TableModule,
        SelectButtonModule,
        IconComponent,
        MenuComponent,
        TranslateModule,
        ToastModule,
        LoadingComponent,
    ],
    template: `
        <loading />
        <p-toast>
            <ng-template let-message pTemplate="message" let-severity>
                <div class="flex flex-row items-center justify-start w-full">
                    <icon
                        class="mr-item-sm"
                        [style.height]="styles.iconSize"
                        [name]="getIconName(severity.severity)"
                        customColor="white" />

                    {{ message.summary | translate }}
                </div>
            </ng-template>
        </p-toast>
        @if (loading()) {
            <custom-spinner [text]="translations.global.loading" />
        } @else {
            <div class="wrapper" [class.logged-in]="isLoggedIn()">
                @if (isLoggedIn()) {
                    <app-menu />
                }
                <router-outlet />
            </div>
        }
    `,
    styles: `
        :host {
            display: flex;
            flex-direction: column;

            custom-spinner {
                transform: translateY(50px);
                width: 100%;
            }

            .wrapper {
                .logged-in {
                    top: var(--header-height);
                }
            }
        }
    `,
})
export class AppComponent {
    protected readonly translations = translations;
    protected readonly styles = styles;

    private appStarterService = inject(AppStarterService);
    private appService = inject(AppService);
    private userService = inject(UserService);
    private appNavigationService = inject(AppNavigationService);
    private appStorageService = inject(AppStorageService);
    private toastService = inject(CustomMessageService);
    private loadingService = inject(LoadingService);

    loading = inject(AppService).loading;
    user = inject(UserService).user;

    isLoggedIn = computed(() => this.userService.isLoggedIn() && this.userService.user().hasRole);

    constructor() {
        this.appStarterService.initServices().then(async hasError => {
            if (hasError) {
                await this.appService.logout();
            } else {
                if (this.user().redirectToLanding) {
                    this.appNavigationService.navigateTo('auth', {
                        parameter: 'landing',
                    });
                }
            }
            this.appService.stopLoading();
            // this.loadingService.show();
            // setTimeout(() => {
            //     this.loadingService.hide();
            // }, 500);
            // this.toastService.success('test', 100000);
            // this.toastService.error('test', 100000);
            // this.toastService.info('test', 100000);
        });
    }

    getIconName(severity: string): TIcon {
        switch (severity) {
            case 'success':
                return 'check';
            case 'error':
                return 'alert-triangle';
            default:
                return 'info';
        }
    }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ISpinner {
    show: boolean;
    count: number;
}

@Injectable({
    providedIn: 'root',
})
export class SpinnerService {
    private readonly spinner$ = new BehaviorSubject<ISpinner>({
        show: false,
        count: 0,
    });

    readonly spinnerObservable$ = this.spinner$.asObservable();

    show() {
        this.spinner$.next({
            show: true,
            count: this.spinner.count++,
        });
    }

    hide() {
        const count = this.spinner.count - 1;

        this.spinner$.next({
            show: count > 0,
            count: count,
        });
    }

    get spinner(): ISpinner {
        return this.spinner$.getValue();
    }
}

import { inject, Injectable, signal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { environment } from '@env/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AppStorageService } from '@shared/services/app-storage.service';
import { translations } from '@shared/utils/translations';
import { PrimeNG } from 'primeng/config';
import { map } from 'rxjs';

const STORAGE_NAME: string = environment.localStorageName + '_language';

@Injectable({
    providedIn: 'root',
})
export class AppLanguageService {
    private appStorage: AppStorageService = inject(AppStorageService);
    private translate: TranslateService = inject(TranslateService);
    private primeng = inject(PrimeNG);

    languages: Signal<string[]> = signal(['en']);

    current: Signal<string> = toSignal(
        this.translate.onLangChange.pipe(map((lang: LangChangeEvent) => lang.lang)),
        { initialValue: 'en' },
    );

    constructor() {
        this.translate.store.onLangChange.subscribe(() => {
            this.translatePrimeng();
        });
    }

    fnInit() {
        this.translate.setDefaultLang('en');

        const language: string | undefined = this.appStorage.getItem(STORAGE_NAME);

        if (language) this.translate.use(language);
    }

    set(lang: string) {
        this.translate.use(lang);
        this.appStorage.setItem(STORAGE_NAME, lang);
    }

    translatePrimeng() {
        this.primeng.setTranslation({
            // Filter operations
            startsWith: this.translate.instant(translations.primeng.startsWith),
            contains: this.translate.instant(translations.primeng.contains),
            notContains: this.translate.instant(translations.primeng.notContains),
            endsWith: this.translate.instant(translations.primeng.endsWith),
            equals: this.translate.instant(translations.primeng.equals),
            notEquals: this.translate.instant(translations.primeng.notEquals),
            noFilter: this.translate.instant(translations.primeng.noFilter),
            lt: this.translate.instant(translations.primeng.lt),
            lte: this.translate.instant(translations.primeng.lte),
            gt: this.translate.instant(translations.primeng.gt),
            gte: this.translate.instant(translations.primeng.gte),
            is: this.translate.instant(translations.primeng.is),
            isNot: this.translate.instant(translations.primeng.isNot),
            before: this.translate.instant(translations.primeng.before),
            after: this.translate.instant(translations.primeng.after),
            dateIs: this.translate.instant(translations.primeng.dateIs),
            dateIsNot: this.translate.instant(translations.primeng.dateIsNot),
            dateBefore: this.translate.instant(translations.primeng.dateBefore),
            dateAfter: this.translate.instant(translations.primeng.dateAfter),

            // Actions
            clear: this.translate.instant(translations.primeng.clear),
            apply: this.translate.instant(translations.primeng.apply),
            matchAll: this.translate.instant(translations.primeng.matchAll),
            matchAny: this.translate.instant(translations.primeng.matchAny),
            addRule: this.translate.instant(translations.primeng.addRule),
            removeRule: this.translate.instant(translations.primeng.removeRule),
            accept: this.translate.instant(translations.primeng.accept),
            reject: this.translate.instant(translations.primeng.reject),
            choose: this.translate.instant(translations.primeng.choose),
            upload: this.translate.instant(translations.primeng.upload),
            cancel: this.translate.instant(translations.primeng.cancel),

            // Messages
            weak: this.translate.instant(translations.primeng.weak),
            medium: this.translate.instant(translations.primeng.medium),
            strong: this.translate.instant(translations.primeng.strong),
            passwordPrompt: this.translate.instant(translations.primeng.passwordPrompt),
            emptyMessage: this.translate.instant(translations.primeng.emptyMessage),
            emptyFilterMessage: this.translate.instant(translations.primeng.emptyFilterMessage),
            fileChosenMessage: this.translate.instant(translations.primeng.fileChosenMessage),
            noFileChosenMessage: this.translate.instant(translations.primeng.noFileChosenMessage),
            pending: this.translate.instant(translations.primeng.pending),

            // Calendar
            chooseYear: this.translate.instant(translations.primeng.chooseYear),
            chooseMonth: this.translate.instant(translations.primeng.chooseMonth),
            chooseDate: this.translate.instant(translations.primeng.chooseDate),
            prevDecade: this.translate.instant(translations.primeng.prevDecade),
            nextDecade: this.translate.instant(translations.primeng.nextDecade),
            prevYear: this.translate.instant(translations.primeng.prevYear),
            nextYear: this.translate.instant(translations.primeng.nextYear),
            prevMonth: this.translate.instant(translations.primeng.prevMonth),
            nextMonth: this.translate.instant(translations.primeng.nextMonth),
            prevHour: this.translate.instant(translations.primeng.prevHour),
            nextHour: this.translate.instant(translations.primeng.nextHour),
            prevMinute: this.translate.instant(translations.primeng.prevMinute),
            nextMinute: this.translate.instant(translations.primeng.nextMinute),
            prevSecond: this.translate.instant(translations.primeng.prevSecond),
            nextSecond: this.translate.instant(translations.primeng.nextSecond),
            am: this.translate.instant(translations.primeng.am),
            pm: this.translate.instant(translations.primeng.pm),

            // Search/Selection
            searchMessage: this.translate.instant(translations.primeng.searchMessage),
            selectionMessage: this.translate.instant(translations.primeng.selectionMessage),
            emptySelectionMessage: this.translate.instant(
                translations.primeng.emptySelectionMessage,
            ),
            emptySearchMessage: this.translate.instant(translations.primeng.emptySearchMessage),

            // Date arrays
            dayNames: this.translate.instant(translations.primeng.dayNames),
            dayNamesShort: this.translate.instant(translations.primeng.dayNamesShort),
            dayNamesMin: this.translate.instant(translations.primeng.dayNamesMin),
            monthNames: this.translate.instant(translations.primeng.monthNames),
            monthNamesShort: this.translate.instant(translations.primeng.monthNamesShort),

            // Date format
            dateFormat: this.translate.instant(translations.primeng.dateFormat),
            firstDayOfWeek: parseInt(this.translate.instant(translations.primeng.firstDayOfWeek)),
            today: this.translate.instant(translations.primeng.today),
            weekHeader: this.translate.instant(translations.primeng.weekHeader),

            // File size
            fileSizeTypes: this.translate.instant(translations.primeng.fileSizeTypes),

            // Aria labels
            aria: {
                trueLabel: this.translate.instant(translations.primeng.aria.trueLabel),
                falseLabel: this.translate.instant(translations.primeng.aria.falseLabel),
                nullLabel: this.translate.instant(translations.primeng.aria.nullLabel),
                pageLabel: this.translate.instant(translations.primeng.aria.pageLabel),
                firstPageLabel: this.translate.instant(translations.primeng.aria.firstPageLabel),
                lastPageLabel: this.translate.instant(translations.primeng.aria.lastPageLabel),
                nextPageLabel: this.translate.instant(translations.primeng.aria.nextPageLabel),
                previousPageLabel: this.translate.instant(
                    translations.primeng.aria.previousPageLabel,
                ),
            },
        });
    }
}

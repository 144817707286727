import { Injectable, signal } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private _state = signal(false);

    state = this._state.asReadonly();

    show(): void {
        this._state.set(true);
    }
    hide(): void {
        this._state.set(false);
    }
}

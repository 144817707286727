{
    "username": "alex_smith",
    "email": "alex.smith@company.com",
    "sub": "auth0|987654321",
    "environment": "dev",
    "role": {
        "user": true,
        "admin": true
    },
    "appVersion": "1.5.0"
}
